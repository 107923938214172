var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('nav', {
    staticClass: "join-step"
  }, [_c('ul', {
    staticClass: "join-step-lists"
  }, [_c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 1")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("약관동의")])]), _c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 2")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("정보입력")])]), _c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("STEP 3")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("가입완료")])])])]), _c('v-layout', {
    staticClass: "text-center",
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-check mb-12 mb-lg-24"
  }), _c('h4', {
    staticClass: "tit tit--sm text-center"
  }, [_vm._v("회원가입이 완료되었습니다.")])]), _c('div', {
    staticClass: "v-btn--group mt-lg-40"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "to": "/",
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("메인으로 이동")])], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }