<template>
    <client-page>
        <section class="section">
            <v-container>
                <h2 class="join-title">회원가입</h2>

                <div class="join-container">
                    <!-- 가입 단계 표시 -->
                    <nav class="join-step">
                        <ul class="join-step-lists">
                            <li class="list list--on">
                                <span class="badge">STEP 1</span>
                                <span class="text">약관동의</span>
                            </li>
                            <li class="list list--on">
                                <span class="badge">STEP 2</span>
                                <span class="text">정보입력</span>
                            </li>
                            <li class="list list--on">
                                <span class="badge">STEP 3</span>
                                <span class="text">가입완료</span>
                            </li>
                        </ul>
                    </nav>

                    <v-layout column align-center justify-center class="text-center">
                        <i class="icon icon-check mb-12 mb-lg-24"></i>
                        <h4 class="tit tit--sm text-center">회원가입이 완료되었습니다.</h4>
                    </v-layout>

                    <div class="v-btn--group mt-lg-40">
                        <v-btn to="/" large color="primary" class="min-w-140px min-w-lg-160px">메인으로 이동</v-btn>
                    </div>

                    
                </div>
            </v-container>
        </section>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default{
	components: {
		ClientPage,
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
